/* RESPONSIBLE TEAM: team-proactive-support */
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  collectionKey: 'dkim_settings',
  attrs: {
    domainKeys: { embedded: 'always' },
  },
});
