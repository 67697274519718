/* RESPONSIBLE TEAM: team-standalone */
import Service, { inject as service } from '@ember/service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import { type ChartTemplate, type ReportTemplate } from 'embercom/services/reporting-templates';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { type Attribute } from 'embercom/objects/reporting/unified/datasets/types';
import { isPresent } from '@ember/utils';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';

export const ALLOWED_REPORT_TEMPLATES = ['fin_ai_agent', 'conversations', 'other'];
export const ALLOWED_METRIC_IDS = [
  'v1.new_conversations',
  'fin.conversations_resolved.count',
  'fin.conversations_answered.count',
  'fin.conversations_participated.count',
  'fin.participation_rate',
  'fin.resolution_rate',
  'fin.conversations_deflected.count',
  'fin.deflection_rate',
  'fin.conversations_abandoned.count',
  'fin.abandonment_rate',
  'fin.answer_rate',
  'fin.conversations_confirmed_resolved.count',
  'fin.conversations_assumed_resolved.count',
  'fin.conversations_routed_to_team.count',
  'fin.routed_to_team_rate',
  'fin.unanswered.count',
  'fin.unresolved_conversations.count',
  'fin.ai_answer.count',
  'fin.custom_answer.count',
  'fin.ai_answer.resolution_rate',
  'fin.custom_answer.resolution_rate',
  'conversation_rating.ai_agent.csat',
  'conversation_rating.ai_agent.positively_rated.count',
  'conversation_rating.ai_agent.negatively_rated.count',
  'conversation_rating.ai_agent.count',
  'conversation_rating.ai_agent.dsat',
  'conversation_rating.any_agent.count',
];

export const ALLOWED_ATTRIBUTE_IDS = [
  'conversation.id',
  'conversation.first_user_conversation_part_created_at',
  'conversation.channel_type',
  'conversation.conversation_state',
  'conversation.user.location.continent_code',
  'conversation.user.location.country_code',
  'conversation.first_user.name',
  'conversation.first_user.email',
  'conversation_custom_fields#standalone_zendesk_brand',
  'conversation.conversation_tag_ids',
];

export const ALLOWED_FILTERABLE_ATTRIBUTE_IDS = [
  'conversation.channel_type',
  'conversation.conversation_state',
  'conversation.user.location.continent_code',
  'conversation.user.location.country_code',
  'conversation.first_user.name',
  'conversation.first_user.email',
  'conversation_custom_fields#standalone_zendesk_brand',
  'conversation.conversation_tag_ids',
];

export const ALLOWED_FIN_ATTRIBUTE_IDS = [
  'conversation.replied.fin_ai_agent',
  'conversation.fin.last_sent_answer_type',
  'conversation.fin.resolution_state',
  'conversation.fin.deflected',
  'conversation.fin.participated',
  'conversation.fin.content_references',
];

export const ALLOWED_FIN_CSAT_ATTRIBUTE_IDS = [
  'conversation.bot_conversation_rating.updated_at',
  'conversation.bot_conversation_rating.rating_index',
  'conversation.bot_conversation_rating.remark',
  'conversation_rating_sent.updated_at',
  'conversation_rating_sent.rating_index',
  'conversation_rating_sent.rating_participants.fin',
  'conversation_rating_sent.rated_actor_type',
  'conversation_rating_sent.last_survey_sent_conversation_part_id',
  'conversation_rating_sent.rated_actor_type',
];

export const HIDDEN_FEATURES = ['showReportOwner', 'showReportAction', 'shareReport'];

export const ALLOWED_CDA_ATTRIBUTES_GROUPS = ['conversation_custom_fields', 'user.custom_data'];

export default class ReportingStandalone extends Service {
  @service declare reportingMetrics: ReportingMetrics;
  @service declare reportingTemplates: ReportingTemplates;
  @service declare appService: any;

  defaultDatasetId = 'conversation';

  filterReportTemplates(reportTemplates: ReportTemplate[]) {
    return reportTemplates.filter((template: ReportTemplate) =>
      ALLOWED_REPORT_TEMPLATES.includes(template.templateId),
    );
  }

  filterStandaloneCharts(reportTemplates: any[]) {
    let attributes = this.getAttributesForStandalone(this.defaultDatasetId).map(
      (attribute: Attribute) => attribute.id,
    );

    return reportTemplates.reduce((filteredTemplates: any[], reportTemplate: any) => {
      if (ALLOWED_REPORT_TEMPLATES.includes(reportTemplate.reportId)) {
        let chartTemplates = reportTemplate.chartTemplates.filter((chart: ChartTemplate) => {
          let includesOnlyAllowedMetrics = chart.chartSeries.every((series: ChartSeries) =>
            ALLOWED_METRIC_IDS.includes(series.metricId),
          );
          let viewByIsAllowed =
            !isPresent(chart.viewBy) ||
            chart.viewBy === 'time' ||
            attributes.includes(chart.viewBy);
          let segmentByIsAllowed =
            !isPresent(chart.segmentBy) ||
            chart.segmentBy === 'time' ||
            attributes.includes(chart.segmentBy);
          return includesOnlyAllowedMetrics && viewByIsAllowed && segmentByIsAllowed;
        });

        if (chartTemplates.length > 0) {
          filteredTemplates.push({
            ...reportTemplate,
            chartTemplates,
          });
        }
      }

      return filteredTemplates;
    }, []);
  }

  getAttributesForStandalone(datasetId: string) {
    let attributes = this.reportingMetrics.getDatasetById(datasetId).attributes;
    let standardAttributes = attributes.filter((attribute: Attribute) =>
      ALLOWED_ATTRIBUTE_IDS.includes(attribute.id),
    );
    let cdaAttributes = attributes.filter((attribute: Attribute) =>
      ALLOWED_CDA_ATTRIBUTES_GROUPS.includes(attribute.group!),
    );
    let finAttributes = attributes.filter(
      (attribute: Attribute) =>
        attribute.group === 'fin' && ALLOWED_FIN_ATTRIBUTE_IDS.includes(attribute.id),
    );

    let result = [...standardAttributes, ...cdaAttributes, ...finAttributes];
    let finCSATAttributesIds = attributes.filter((attribute: Attribute) =>
      ALLOWED_FIN_CSAT_ATTRIBUTE_IDS.includes(attribute.id),
    );
    result = result.concat(finCSATAttributesIds);
    return result.compact();
  }

  supportsFeature(featureName: string) {
    return !HIDDEN_FEATURES.includes(featureName);
  }

  filterMetrics(metrics: Metric[]) {
    return metrics.filter((metric: Metric) => ALLOWED_METRIC_IDS.includes(metric.id));
  }

  filterSuggestedAttributes(attributeIds: string[]) {
    let attributes = [
      ...ALLOWED_ATTRIBUTE_IDS,
      ...ALLOWED_FIN_ATTRIBUTE_IDS,
      ...ALLOWED_FIN_CSAT_ATTRIBUTE_IDS,
    ];
    return attributeIds.filter((attributeId: string) => attributes.includes(attributeId));
  }
}
