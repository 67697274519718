/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Service from '@ember/service';
import ENV from 'embercom/config/environment';

export default class RegionService extends Service {
  constructor() {
    super(...arguments);
    this.safeBrowsingIntercomHosts = this.generateSafeBrowsingIntercomHosts();
  }

  US_EAST_1 = 'us-east-1';
  EU_WEST_1 = 'eu-west-1';
  AP_SOUTHEAST_2 = 'ap-southeast-2';

  API_BASE_URLS = {
    [this.US_EAST_1]: 'https://api-iam.intercom.io',
    [this.EU_WEST_1]: 'https://api-iam.eu.intercom.io',
    [this.AP_SOUTHEAST_2]: 'https://api-iam.au.intercom.io',
  };

  PUBLIC_API_BASE_URLS = {
    [this.US_EAST_1]: 'https://api.intercom.io',
    [this.EU_WEST_1]: 'https://api.eu.intercom.io',
    [this.AP_SOUTHEAST_2]: 'https://api.au.intercom.io',
  };

  APP_HOSTS = {
    [this.US_EAST_1]: 'app.intercom.com',
    [this.EU_WEST_1]: 'app.eu.intercom.com',
    [this.AP_SOUTHEAST_2]: 'app.au.intercom.com',
  };

  MOBILE_MESSENGER_URLS = {
    [this.EU_WEST_1]: 'mobile-messenger.eu.intercom.io',
    [this.AP_SOUTHEAST_2]: 'mobile-messenger.au.intercom.io',
  };

  DOWNLOADS_CDN_URLS = {
    [this.US_EAST_1]: 'downloads.intercomcdn.com',
    [this.EU_WEST_1]: 'downloads.intercomcdn.eu',
    [this.AP_SOUTHEAST_2]: 'downloads.au.intercomcdn.com',
  };

  MISCELLANEOUS_CDN_URLS = ['video-messages.intercomcdn.com', 'gifs.intercomcdn.com'];

  ATTACHMENT_CDN_URLS = {
    [this.US_EAST_1]: 'intercom-attachments.com',
    [this.EU_WEST_1]: 'intercom-attachments.eu',
    [this.AP_SOUTHEAST_2]: 'au.intercom-attachments.com',
  };

  ADDITIONAL_ATTACHMENT_CDN_URLS = [
    'intercom-attachments-1.com',
    'intercom-attachments-2.com',
    'intercom-attachments-3.com',
    'intercom-attachments-4.com',
    'intercom-attachments-5.com',
    'intercom-attachments-6.com',
    'intercom-attachments-7.com',
    'intercom-attachments-8.com',
    'intercom-attachments-9.com',
    'intercom-attachments-10.com',
    'intercom-attachments-11.com',
    'intercom-attachments-12.com',
  ];

  INTEGRATION_URLS = {
    salesforce: {
      [this.US_EAST_1]: 'https://salesforce.intercom.io',
      [this.EU_WEST_1]: 'https://salesforce.eu.intercom.io',
      [this.AP_SOUTHEAST_2]: 'https://salesforce.au.intercom.io',
    },
    quickLinks: {
      [this.US_EAST_1]: 'https://intercom-integrations.intercom.io',
      [this.EU_WEST_1]: 'https://intercom-integrations.eu.intercom.io',
      [this.AP_SOUTHEAST_2]: 'https://intercom-integrations.au.intercom.io',
    },
    slack: {
      [this.US_EAST_1]: 'https://slack.intercom.io',
      [this.EU_WEST_1]: 'https://slack.eu.intercom.io',
      [this.AP_SOUTHEAST_2]: 'https://slack.au.intercom.io',
    },
    messengerApps: {
      [this.US_EAST_1]: 'https://messenger-apps.intercom.io',
      [this.EU_WEST_1]: 'https://messenger-apps.eu.intercom.io',
      [this.AP_SOUTHEAST_2]: 'https://messenger-apps.au.intercom.io',
    },
    calendar: {
      [this.US_EAST_1]: 'meet.intercom.com',
      [this.EU_WEST_1]: 'meet.eu.intercom.com',
      [this.AP_SOUTHEAST_2]: 'meet.au.intercom.com',
    },
  };

  INTERCOMRADE_URLS = {
    [this.US_EAST_1]: 'https://intercomrades.intercom.com',
    [this.EU_WEST_1]: 'https://intercomrades.eu.intercom.com',
    [this.AP_SOUTHEAST_2]: 'https://intercomrades.au.intercom.com',
  };

  INTERCOM_APP_CODES = {
    [this.US_EAST_1]: 'tx2p130c',
    [this.EU_WEST_1]: 'olpw3290',
    [this.AP_SOUTHEAST_2]: 'pnroknvu',
  };

  DISPLAY_NAMES = [
    { value: this.US_EAST_1, text: 'United States' },
    { value: this.EU_WEST_1, text: 'Europe' },
    { value: this.AP_SOUTHEAST_2, text: 'Australia' },
  ];

  TELEMETRY_BASE_URLS = {
    [this.US_EAST_1]: 'https://frontend-telemetry.intercom.io',
    [this.EU_WEST_1]: 'https://frontend-telemetry.eu.intercom.io',
    [this.AP_SOUTHEAST_2]: 'https://frontend-telemetry.au.intercom.io',
  };

  INTERCOM_MARKETING_SITES = [
    'https://www.intercom.com',
    'https://intercom.com',
    'https://inter.com',
    'https://developers.intercom.com',
    'https://academy.intercom.com',
    'https://community.intercom.com',
    'https://go.intercom.com',
    'https://intercom.intercom-mail.com',
    'https://intercom.intercom-clicks.com',
    'https://via.intercom.io',
  ];

  TRUSTED_THIRD_PARTY_DOMAINS = ['mail-settings.google.com'];

  get region() {
    let region = document.querySelector("meta[name='intercom_region']")?.content;
    return region || this.US_EAST_1;
  }

  get isUS() {
    return this.region === this.US_EAST_1;
  }

  get isEU() {
    return this.region === this.EU_WEST_1;
  }

  get isAU() {
    return this.region === this.AP_SOUTHEAST_2;
  }

  get apiBaseURL() {
    return this.API_BASE_URLS[this.region];
  }

  get publicApiBaseURL() {
    return this.PUBLIC_API_BASE_URLS[this.region];
  }

  get mobileMessengerURL() {
    return this.MOBILE_MESSENGER_URLS[this.region];
  }

  integrationURL(integration) {
    if (ENV.environment === 'development' || ENV.environment === 'test') {
      return 'http://localhost:3040';
    }
    return this.INTEGRATION_URLS[integration][this.region];
  }

  get salesforceBaseURL() {
    return this.integrationURL('salesforce');
  }

  get quickLinksBaseURL() {
    return this.integrationURL('quickLinks');
  }

  get slackInstallStartURL() {
    let url = this.integrationURL('slack');
    return `${url}/slack/authenticate/`;
  }

  get calendarURL() {
    return this.integrationURL('calendar');
  }

  get messengerAppsBaseURL() {
    return this.integrationURL('messengerApps');
  }

  get intercomAppCode() {
    return this.INTERCOM_APP_CODES[this.region];
  }

  get intercomUSAppCode() {
    return this.INTERCOM_APP_CODES[this.US_EAST_1];
  }

  get teammateAppBaseURL() {
    return this.appUrl(this.region);
  }

  appUrl(region) {
    if (ENV.environment === 'development' || ENV.environment === 'test') {
      return 'http://app.intercom.test';
    }
    return `https://${this.APP_HOSTS[region]}`;
  }

  get appHost() {
    return this.APP_HOSTS[this.region];
  }

  intercomradesUrl(region) {
    if (ENV.environment === 'development' || ENV.environment === 'test') {
      return 'http://intercomrades.intercom.test';
    }
    return this.INTERCOMRADE_URLS[region];
  }

  impersonationUrl(region) {
    let intercomAppId = this.INTERCOM_APP_CODES[region];
    return `${this.intercomradesUrl(region)}/a/apps/${intercomAppId}/impersonate`;
  }

  telemetryBaseUrl() {
    if (ENV.environment === 'development' || ENV.environment === 'test') {
      return '';
    }
    return this.TELEMETRY_BASE_URLS[this.region];
  }

  generateSafeBrowsingIntercomHosts() {
    let urls = [];
    if (ENV.environment === 'development' || ENV.environment === 'test') {
      urls = [
        'intercom.test',
        'intercomrades.intercom.test',
        'app.intercom.test',
        'localhost:7357',
        'localhost:4200',
        'uploads-development.s3.test',
      ];
    }
    return urls
      .concat(
        Object.values(this.APP_HOSTS),
        Object.values(this.INTERCOMRADE_URLS),
        this.INTERCOM_MARKETING_SITES,
        Object.values(this.DOWNLOADS_CDN_URLS),
        this.MISCELLANEOUS_CDN_URLS,
        Object.values(this.ATTACHMENT_CDN_URLS),
        this.ADDITIONAL_ATTACHMENT_CDN_URLS,
        this.TRUSTED_THIRD_PARTY_DOMAINS,
      )
      .map((url) => url.replace('https://', ''));
  }
}
