/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  primaryKey: 'identifier',
  attrs: {
    steps: { embedded: 'always' },
  },
});
