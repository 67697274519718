/* RESPONSIBLE TEAM: team-growth-opportunities */
import Service from '@ember/service';
//@ts-ignore
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

export default class extends Service {
  replace(url: string): void {
    let sanitizedUrl = sanitizeUrl(url).string;
    window.location.replace(sanitizedUrl);
  }
}
