/* RESPONSIBLE TEAM: team-growth-opportunities */
import Service, { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import {
  PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
} from 'embercom/lib/billing';

const SERVICE_PREFIX = 'early-stage-service-';
const PARTNER_SOURCE_KEY = `${SERVICE_PREFIX}partner-source`;

export default class EarlyStageService extends Service {
  @service customerService;

  isEarlyStage(solutionId) {
    return PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(solutionId);
  }

  isEarlyStageFreeSolutionId(solutionId) {
    return PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID.includes(solutionId);
  }

  get partnerSource() {
    return localStorage.getItem(PARTNER_SOURCE_KEY);
  }

  set partnerSource(partnerSource) {
    if (partnerSource) {
      localStorage.setItem(PARTNER_SOURCE_KEY, partnerSource);
    } else {
      localStorage.removeItem(PARTNER_SOURCE_KEY);
    }
  }

  get isPartnerValid() {
    if (!this.isPartnerProgram) {
      return false;
    }
    let partnerNames = this.partners?.map((partner) => partner.toLowerCase());
    return partnerNames.includes(this.partnerSource.toLowerCase());
  }

  get isPartnerProgram() {
    return !!this.partnerSource;
  }

  async fetchEarlyStagePartners() {
    if (this.partners) {
      return this.partners;
    }
    let response = await ajax({ url: '/api/early-stage-partners' });
    this.partners = response?.partners;
    return this.partners;
  }
}
