/* RESPONSIBLE TEAM: team-workflows */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class WorkflowLocalizationService extends Service {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;
  @tracked selectedLocaleId: string | null = this.defaultLocaleId;

  get languages() {
    return this.store.peekRecord('messenger-settings/languages', this.appService.app.id);
  }

  get availableLocalesForEditor() {
    return (
      this.languages?.supportedLocales.filter(
        (locale) => locale.isPermitted || locale.localeId === this.defaultLocaleId,
      ) || []
    );
  }

  get defaultLocale() {
    return this.availableLocalesForEditor.find(
      (locale) => locale.localeId === this.defaultLocaleId,
    )!;
  }

  get defaultLocaleId() {
    return this.languages?.defaultLocale || 'en';
  }

  get selectedLocale() {
    return (
      this.availableLocalesForEditor.find((locale) => locale.localeId === this.selectedLocaleId) ||
      this.defaultLocale
    );
  }

  get selectedLocaleName() {
    return this.selectedLocale.name;
  }

  get isMultilingualEnabled(): boolean {
    return this.contentEditorService.activeObject?.botData?.multilingualEnabled ?? false;
  }

  @action
  selectLanguage(language: string) {
    this.selectedLocaleId = language;
  }
}

declare module '@ember/service' {
  interface Registry {
    workflowLocalizationService: WorkflowLocalizationService;
    'workflow-localization-service': WorkflowLocalizationService;
  }
}
