/* RESPONSIBLE TEAM: team-tickets-1 */
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import { inject as service } from '@ember/service';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type Session from 'embercom/services/session';
import { enqueueTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'embercom/services/intl';
import type Snackbar from 'embercom/services/snackbar';
import { type Descriptor } from 'embercom/objects/inbox/types/descriptor';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import { type AttributeSummary } from 'embercom/objects/inbox/types/attribute-summary';
import type { TicketDescriptor } from 'embercom/objects/inbox/ticket';
import type LogService from 'embercom/services/log-service';
import { captureMessage } from 'embercom/lib/sentry';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { setOwner } from '@ember/application';
import ENV from 'embercom/config/environment';
import { type Upload } from 'embercom/objects/inbox/renderable/upload';

export default class ConditionalAttributesEvaluator {
  @service declare session: Session;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;
  @service declare logService: LogService;
  @service declare inboxSidebarService: InboxSidebarService;

  conversation: ConversationRecord = {} as ConversationRecord;
  attributeValues: Record<string, AttributeSummary> = {};
  attributeDescriptors: Descriptor[] = [];
  listOptions: Record<string, string[]> = {};
  unsetAttributeBackend = true;

  beforeAttributeUpdateHook?: (attribute: ConversationAttributeSummary) => void;

  /**
   * Initializes the service with the provided conversation, descriptors, and attributes.
   * When initializing the service for new tickets, the conversation record may not exist yet, so attributes must be explicitly passed.
   * If a conversation record exists, its attributes are used.
   * Optimistic updates are enabled by default but can be disabled in specific cases (e.g., creating a new ticket).
   *
   * @returns {this}
   */
  constructor({
    conversation,
    descriptors,
    attributes,
    owner,
    unsetAttributeBackend = true,
  }: {
    conversation?: ConversationRecord;
    descriptors: Descriptor[];
    attributes?: TicketAttributeSummary[] | ConversationAttributeSummary[];
    unsetAttributeBackend?: boolean;
    owner: unknown;
  }) {
    setOwner(this, owner);
    if (conversation) {
      // If conversation is provided, we use the attributes from the conversation record
      this.conversation = conversation;
      attributes = conversation.attributes;
    }

    this.attributeValues = this.attributesById(attributes);
    this.attributeDescriptors = descriptors || [];

    descriptors?.forEach((attribute) => this.updateConditionalOptions(attribute, false));

    this.unsetAttributeBackend = unsetAttributeBackend;

    return this;
  }

  isConditionalAttribute(attribute: AttributeSummary): boolean {
    let hasConversationAttrsFF = this.session.workspace.canUseConversationConditionalAttributesBeta;
    let hasTicketAttrsFF = this.session.workspace.canUseTicketsConditionalAttributes;

    let isTicketAttribute = !!(attribute.descriptor as TicketDescriptor).ticketTypeId;

    return (
      (hasConversationAttrsFF && !isTicketAttribute) || (hasTicketAttrsFF && isTicketAttribute)
    );
  }

  /**
   * Updates attribute in create / convert ticket forms and handle related changes
   *
   * @returns {void}
   *
   * @description
   * - Limit list options based on conditions
   * - Update visibility of dependent attributes
   */
  onChangeAttribute(attribute: TicketAttributeSummary) {
    this.recalculateDependentAttributesOptions(attribute);
    this.updateVisibility(attribute);
  }

  /**
   * Updates attribute and handles related changes
   *
   * @param {ConversationAttributeSummary} attribute
   * @returns {Promise<void>}
   *
   * @description
   * - Optimistically updates the attribute value
   * - Limit list options based on conditions
   * - Update attribute on the backend
   * - Rollback if the update fails
   * - Update visibility of dependent attributes
   */
  async updateAttribute(
    attribute: ConversationAttributeSummary,
    conversation?: ConversationRecord,
  ) {
    if (this.isUpdatingAttributeForIncorrectConversation(attribute, conversation)) {
      return;
    }

    let update = this.conversationUpdates.addUpdate(
      this.conversation.id,
      'conversation-attribute-update',
      { attribute, displayValue: this.getDisplayValue(attribute) },
    );

    if (this.isConditionalAttribute(attribute)) {
      this.beforeAttributeUpdateHook?.(attribute);
      this.recalculateDependentAttributesOptions(attribute);
    }

    taskFor(this.persistAttribute)
      .perform(this.conversation.id, attribute, update.part.clientAssignedUuid)
      .catch((error) => {
        if (error?.name === 'TaskCancelation') {
          // We're on a slow network and the previous update was replaced with another one
          // we can move on - it's not an error, but an expected behavior
          return;
        }
        update.rollback(this.conversation);
        let updates = this.conversationUpdates.updatesFor(this.conversation.id);
        this.conversationUpdates.rollbackUpdates(this.conversation.id, updates);

        this.snackbar.notifyError(
          this.intl.t(
            'inbox.conversation-details-sidebar.attributes.errors.updating-user-attribute',
            { attributeName: attribute.descriptor.name },
          ),
        );
      })
      .finally(() => {
        if (this.isConditionalAttribute(attribute)) {
          this.updateVisibility(attribute);
        }
      });
  }

  isUpdatingAttributeForIncorrectConversation(
    attribute: ConversationAttributeSummary,
    conversation?: ConversationRecord,
  ) {
    if (
      ENV.environment !== 'test' &&
      !window.location.pathname.includes('dashboard') && // we can't verify ID if the conversation is opened on the dashboard view
      !window.location.pathname.includes(String(this.conversation.id)) &&
      !this.inboxSidebarService.isPreviewingConversation &&
      !this.inboxSidebarService.isViewingSideConversation
    ) {
      this.logService.log({
        log_type: 'conditionalAttributesService.updateAttribute',
        app_id: this.session.workspace.id,
        current_path: window.location.pathname,
        current_conversation_id: this.conversation.id,
        has_mismatch: true,
      });

      captureMessage('conditionalAttributesService.updateAttribute-hasMismatch', {
        extra: {
          currentConversationId: this.conversation.id,
          newConversationId: conversation?.id,
        },
        tags: {
          responsible_team: 'team-tickets-1',
          responsibleTeam: 'team-tickets-1',
        },
      });

      this.snackbar.notifyError(
        this.intl.t('inbox.conversation-details-sidebar.attributes.errors.invalid-conversation', {
          attributeName: attribute.descriptor.name,
        }),
      );

      return true;
    }

    return false;
  }

  recalculateDependentAttributesOptions(attribute: AttributeSummary) {
    let dependentAttributes = this.getDependentAttributes(
      this.attributeDescriptorsMap[attribute.descriptor.id as number],
    );
    dependentAttributes.filter(this.hasListOptions.bind(this)).forEach((dependentAttribute) => {
      this.updateConditionalOptions(dependentAttribute, true);
    });
  }

  updateVisibility(attribute?: AttributeSummary): Descriptor[] {
    if (attribute && this.attributeDescriptorsMap[attribute.descriptor.id as number]) {
      return this.evaluateConditionsOnUpdate(
        this.attributeDescriptorsMap[attribute.descriptor.id as number],
      );
    }

    return this.visibleAttributes();
  }

  visibleAttributes(): Descriptor[] {
    let visibleAttributes: Descriptor[] = [];

    // Add non-dependent attributes and attributes with values
    this.attributeDescriptors
      .filter(
        (descriptor) =>
          !descriptor.isDependentAttribute || this.attributeValues[descriptor.id]?.value,
      )
      .forEach((attribute) => this.addToVisibleAttributes(attribute, visibleAttributes));

    // Add dependent attributes that match their controlling attribute conditions
    this.attributeDescriptors
      .filter((descriptor) => descriptor.isControllingAttribute)
      .forEach((controllingAttribute) => {
        let currentValue = this.attributeValues[controllingAttribute.id]?.value as string;

        controllingAttribute.matchedDependents(currentValue).forEach((condition) => {
          let dependentAttribute = this.attributeDescriptorsMap[condition.descriptorId];
          if (dependentAttribute) {
            this.addToVisibleAttributes(dependentAttribute, visibleAttributes);
          }
        });
      });

    return visibleAttributes;
  }

  private hasListOptions(descriptor: Descriptor): boolean {
    return Boolean(descriptor.listOptions) && descriptor.listOptions!.length > 0;
  }

  private evaluateConditionsOnUpdate(attribute: Descriptor): Descriptor[] {
    let visibleAttributes = this.visibleAttributes();
    this.processControllingAttribute(attribute, visibleAttributes);
    return visibleAttributes;
  }

  private processControllingAttribute(
    controllingAttribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): void {
    let dependentAttributes = this.getDependentAttributes(controllingAttribute);

    dependentAttributes.forEach((dependentAttribute) => {
      this.processDependentAttribute(controllingAttribute, dependentAttribute, visibleAttributes);

      if (dependentAttribute.isDependentAttribute) {
        this.processControllingAttribute(dependentAttribute, visibleAttributes);
      }
    });
  }

  private getDependentAttributes(attribute: Descriptor): Descriptor[] {
    return attribute.dependentAttributeIds().map((id) => this.attributeDescriptorsMap[id]);
  }

  private processDependentAttribute(
    controllingAttribute: Descriptor,
    dependentAttribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): void {
    let matchesController = controllingAttribute.shouldShowDependent(
      dependentAttribute,
      this.attributeValues[controllingAttribute.id]?.value as string,
    );
    let matchesAnotherController = this.matchesAnotherController(
      dependentAttribute,
      visibleAttributes,
    );

    if (matchesController || matchesAnotherController) {
      this.addToVisibleAttributes(dependentAttribute, visibleAttributes);
    } else {
      this.removeFromVisibleAttributes(dependentAttribute, visibleAttributes);
    }
  }

  private matchesAnotherController(
    dependentAttribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): boolean {
    let controllingAttributes = this.controllingAttributes(dependentAttribute)?.filter(
      (attribute: Descriptor) => visibleAttributes.some((attr) => attr.id === attribute.id),
    );

    return controllingAttributes.some((controller: Descriptor) => {
      return controller.shouldShowDependent(
        dependentAttribute,
        this.attributeValues[controller.id]?.value as string,
      );
    });
  }

  private addToVisibleAttributes(attribute: Descriptor, visibleAttributes: Descriptor[]): void {
    if (!visibleAttributes.some((attr) => attr.id === attribute.id)) {
      visibleAttributes.push(attribute);
    }
  }

  private removeFromVisibleAttributes(
    attribute: Descriptor,
    visibleAttributes: Descriptor[],
  ): void {
    let index = visibleAttributes.findIndex((attr) => attr.id === attribute.id);
    if (index !== -1) {
      visibleAttributes.splice(index, 1);
      this.unsetAttribute(attribute);
    }
  }

  private updateConditionalOptions(attribute: Descriptor, canUnsetAttributes = false): void {
    if (!this.hasListOptions(attribute)) {
      return;
    }
    if (!attribute.isDependentAttribute) {
      this.listOptions[attribute.id] = attribute.listOptions!.map((option) => option.id);
      return;
    }

    this.listOptions[attribute.id] = this.conditionalOptionIdsFor(attribute, !canUnsetAttributes);

    // in case attribute depends on multiple controllers, there might be a situation
    // where already selected value is not available anymore in the listOptions
    // in this case we should unset the attribute and let the user pick a new value
    // this should be triggered only by user interaction - hence canUnsetAttributes flag
    if (
      canUnsetAttributes &&
      this.attributeValues[attribute.id] &&
      this.attributeValues[attribute.id].value &&
      !this.listOptions[attribute.id].includes(this.attributeValues[attribute.id].value as string)
    ) {
      this.unsetAttribute(attribute);
    }
  }

  private conditionalOptionIdsFor(descriptor: Descriptor, includeCurrentValue = false): string[] {
    // create a new array from matchedControllersListOptions to avoid mutating the original
    let result: string[] = [...descriptor.matchedControllersListOptions(this.attributeValues)];

    // attributes set by automations do not respect conditionality and
    // should be included in the list of accessible options if they are set
    // and also if we're rendering the list for the first time we need to include historical value
    // even if it do not match the conditions
    if (
      (result.length === 0 || includeCurrentValue) &&
      this.attributeValues[descriptor.id]?.value &&
      !result.includes(this.attributeValues[descriptor.id]?.value as string)
    ) {
      result.push(this.attributeValues[descriptor.id]?.value as string);
    }

    return result;
  }

  private attributesById(attributes?: AttributeSummary[]): Record<string, AttributeSummary> {
    return (
      attributes?.reduce(
        (byIds, attribute) => {
          byIds[attribute.descriptor.id] = attribute;
          return byIds;
        },
        {} as Record<string, AttributeSummary>,
      ) ?? {}
    );
  }

  private unsetAttribute(attribute: Descriptor): void {
    let attr = this.attributeValues[attribute.id];
    if (attr?.value) {
      attr.value = undefined;
      if (this.unsetAttributeBackend) {
        this.updateAttribute?.(attr as ConversationAttributeSummary);
      }
    }
  }

  private getDisplayValue(attribute: ConversationAttributeSummary): string | undefined {
    let { type, id } = attribute.descriptor;

    if (
      attribute.value === null ||
      ![DataType.Boolean, DataType.List, DataType.Files].includes(type)
    ) {
      return undefined;
    }

    if (attribute.descriptor.type === DataType.Boolean) {
      return this.intl.t(`inbox.conversation-attributes.boolean.${attribute?.value?.toString()}`);
    }

    if (attribute.descriptor.type === DataType.Files) {
      return (attribute.value as Upload[]).map((file) => file.name).join(', ');
    }

    let descriptor = this.attributeDescriptors.find((x) => x.id === id);
    return (
      descriptor?.listOptions?.find((option) => option.id === attribute.value)?.label || undefined
    );
  }

  @enqueueTask
  private *persistAttribute(
    conversationId: number,
    attribute: ConversationAttributeSummary,
    clientAssignedUuid: string | undefined,
  ) {
    yield this.inboxApi.updateAttribute(conversationId, attribute, clientAssignedUuid);
  }

  private controllingAttributes(attribute: Descriptor): Descriptor[] {
    return attribute
      .controllingAttributeIds()
      .map((id) => this.attributeDescriptorsMap[id])
      .filter(Boolean);
  }

  private get attributeDescriptorsMap(): Record<number, Descriptor> {
    return this.attributeDescriptors.reduce(
      (byIds, descriptor) => {
        byIds[descriptor.id as number] = descriptor;
        return byIds;
      },
      {} as Record<number, Descriptor>,
    );
  }
}
