/* RESPONSIBLE TEAM: team-proactive-support */
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';

export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    nodes: { embedded: 'always' },
    edges: { embedded: 'always' },
    stats: { embedded: 'always' },
    annotations: { embedded: 'always' },
    tags: { key: 'tag_ids' },
    taggings: {
      serialize: 'records',
      deserialize: 'records',
    },
    latestChangelog: { embedded: 'always' },
    createdBy: { key: 'created_by_id' },
    lastStateChange: { key: 'last_state_change' },
  },
});
