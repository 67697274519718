/* RESPONSIBLE TEAM: team-help-desk-experience */

import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import EmbercomBaseSerializer from 'embercom/serializers/base/embercom-base-serializer';
export default EmbercomBaseSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    senderEmailAddresses: { embedded: 'always' },
  },
  serialize(snapshot: any) {
    let json = this._super(...arguments);

    // Add email_setup to the json params if it's available in the record
    if (snapshot.record.emailSetup) {
      json.email_setup = snapshot.record.emailSetup;
    }

    if (snapshot.record.allowForwarding) {
      json.allow_forwarding = snapshot.record.allowForwarding;
    }

    if ('sendVerificationEmail' in snapshot.record) {
      json.send_verification_email = snapshot.record.sendVerificationEmail;
    }

    return json;
  },
});
