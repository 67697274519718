/* RESPONSIBLE TEAM: team-ai-agent */
import Service, { inject as service } from '@ember/service';
import { buildParams, request } from 'embercom/lib/inbox/requests';
import type Session from './session';
import {
  type ConversationContentSettings,
  type ConversationContentSettingsWireFormat,
} from 'embercom/controllers/apps/app/automation/fin-ai-agent/setup';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';

export default class FinConversationContent extends Service {
  @service declare session: Session;

  @use settings = AsyncData<ConversationContentSettings>(async ({ signal }) => {
    let params = buildParams(this.session.workspace.id);
    let response = await request(`/ember/inbox/fin_conversation_content?${params}`, { signal });
    let json = (await response.json()) as ConversationContentSettingsWireFormat;
    return {
      enabled: json.enabled,
      numSnippets: json.num_snippets,
      numSnippetsAvailable: json.num_snippets_available,
      immediatelyAvailableToFin: json.immediately_available_to_fin,
      adminIDs: json.admin_ids || [],
      teamIDs: json.team_ids || [],
      contentFolderId: json.content_folder_id,
    };
  });
}
