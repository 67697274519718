/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';
import type Session from 'embercom/services/session';

export default class ConversationTranslationSettings extends Service {
  @service declare session: Session;
  @tracked autoTranslationEnabled = this.storedValueOrDefault;

  get storedValueOrDefault() {
    if (!this.session.workspace.isFeatureEnabled('realtime-translation')) {
      return false;
    }
    if (storage.get(this.localStorageShowTranslationKey) !== null) {
      return storage.get(this.localStorageShowTranslationKey);
    }
    return false;
  }

  get localStorageShowTranslationKey() {
    let appId = this.session.workspace.id;
    let adminId = this.session.teammate.id;
    return `${appId}::${adminId}::ConversationTranslationSettings::showTranslation`;
  }

  @action toggleTranslation(toggle?: boolean): void {
    if (toggle === undefined) {
      this.autoTranslationEnabled = !this.autoTranslationEnabled;
    } else {
      this.autoTranslationEnabled = toggle;
    }
    storage.set(this.localStorageShowTranslationKey, this.autoTranslationEnabled);
  }
}

declare module '@ember/service' {
  interface Registry {
    conversationTranslationSettings: ConversationTranslationSettings;
    'conversation-translation-settings': ConversationTranslationSettings;
  }
}
