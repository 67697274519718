/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';
import Theme from 'embercom/objects/inbox/types/theme';
import type ReactTeammateAppIslandService from 'embercom/services/react-teammate-app-island';

export default class ThemeService extends Service {
  DEFAULT_THEME = Theme.System;
  media = window.matchMedia('(prefers-color-scheme: dark)');

  @service reactTeammateAppIsland!: ReactTeammateAppIslandService;

  @tracked private _theme?: Theme;
  @tracked private _systemTheme?: Theme.Dark | Theme.Light;
  @tracked private workspaceId?: string;

  constructor() {
    super(...arguments);
    this._systemTheme = this.media.matches ? Theme.Dark : Theme.Light;
    this.media.addEventListener('change', this.handleSystemThemeChange);
  }

  get theme(): Theme {
    return this._theme ?? this.DEFAULT_THEME;
  }

  get systemTheme(): Theme.Dark | Theme.Light {
    return this._systemTheme ?? Theme.Light;
  }

  //Returns the theme to be used by the app, if the theme is set to system, it will return the system theme value (dark or light)
  get computedTheme(): Exclude<Theme, Theme.System> {
    return this.theme === Theme.System ? this.systemTheme : this.theme;
  }

  get darkModeSettingKey() {
    if (!this.workspaceId) {
      console.error('ThemeService.darkModeSettingKey must be called after workspaceId is set');
      return '';
    }
    return `inbox-dark-mode-${this.workspaceId}`;
  }

  get darkModeEnabled(): boolean {
    return this.computedTheme === Theme.Dark;
  }

  get isThemeLoaded(): boolean {
    return this._theme !== undefined;
  }

  clearPersistedTheme() {
    storage.remove(this.darkModeSettingKey);
  }
  private getPersistedTheme() {
    return storage.get(this.darkModeSettingKey);
  }
  private persistTheme() {
    storage.set(this.darkModeSettingKey, this._theme);
  }
  private parsePersistedTheme(persistedTheme: any): Theme {
    if (typeof persistedTheme === 'boolean') {
      return persistedTheme ? Theme.Dark : Theme.Light;
    }
    return Object.values(Theme).find((value) => value === persistedTheme) ?? this.DEFAULT_THEME;
  }

  postBridgeStateChange() {
    this.reactTeammateAppIsland.dispatchAppStateChangeEvent({ theme: this.computedTheme });
  }

  willDestroy() {
    this.media.removeEventListener('change', this.handleSystemThemeChange);
  }

  @action
  setupTheme(workspaceId: string) {
    if (!workspaceId) {
      console.error('ThemeService.setupTheme must be called after workspaceId is set');
      return;
    }

    if (this.workspaceId === workspaceId) {
      return;
    }

    this.workspaceId = workspaceId;

    let persistedTheme = this.getPersistedTheme();
    let theme = this.parsePersistedTheme(persistedTheme);
    this.setTheme(theme);
  }

  @action
  setTheme(theme: Theme) {
    if (!this.workspaceId) {
      console.error('ThemeService is not initialized, please ensure that setupTheme is called.');
      return;
    }

    this._theme = theme;
    this.postBridgeStateChange();
    this.persistTheme();
    this.updateUIWithTheme();
  }

  @action
  teardownTheme() {
    this.media.removeEventListener('change', this.updateUIWithTheme);
    this._theme = undefined;
    this.workspaceId = undefined;
    this.clearUIThemes();
  }

  @action
  private clearUIThemes() {
    document.documentElement.classList.toggle('dark', false);
  }

  @action
  private updateUIWithTheme() {
    if (!this.workspaceId) {
      console.error('ThemeService is not initialized, please ensure that setupTheme is called.');
      return;
    }

    this.clearUIThemes();

    switch (this.theme) {
      case Theme.Dark:
        document.documentElement.classList.toggle('dark', true);
        break;
      case Theme.System:
        if (this.darkModeEnabled) {
          document.documentElement.classList.toggle('dark', true);
        }
        break;
    }
  }

  @action
  private handleSystemThemeChange({ matches: isDarkMode }: MediaQueryListEvent) {
    this._systemTheme = isDarkMode ? Theme.Dark : Theme.Light;
    if (this.theme === Theme.System) {
      this.postBridgeStateChange();
      this.updateUIWithTheme();
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'theme-service': ThemeService;
  }
}
