/* RESPONSIBLE TEAM: team-phone */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { WavStreamPlayer } from '../helpers/wav-tools/wav-stream-player';
import { WavRecorder } from '../helpers/wav-tools/wav-recorder';
import { arrayBufferToBase64 } from '../helpers/wav-tools/array-buffer-to-base64';
import type IntlService from 'ember-intl/services/intl';
import { get } from 'embercom/lib/ajax';
import type {
  FinVoiceEventMedia,
  FinVoiceMarkMessage,
  FinVoiceMediaMessage,
  FinVoiceMessage,
  FinVoiceUserTranscriptError,
  FinVoiceVadSettings,
  FinVoiceToolCallDone,
} from 'embercom/objects/ai-agent/fin-voice/fin-voice-events';
import type { FinVoiceEvent } from 'embercom/objects/ai-agent/fin-voice/fin-voice-events';
import type {
  FinVoiceStartEvent,
  FinVoiceFinTranscriptDone,
  FinVoiceFinTranscriptDelta,
  FinVoiceUserTranscript,
} from 'embercom/objects/ai-agent/fin-voice/fin-voice-events';
import type {
  FinVoiceConversationEntryFunctionCall,
  FinVoiceConversationEntryMessage,
  FinVoiceSession,
  FinVoiceConversationEntry,
} from 'embercom/objects/ai-agent/fin-voice/fin-voice-session';
import type { FinVoiceVoice } from 'embercom/objects/ai-agent/fin-voice/fin-voice-voice-options';

const SAMPLE_RATE = 24000;
const BUFFER_SIZE = 8192;

export type WebsocketState = 'connecting' | 'connected' | 'disconnected' | 'disconnecting';

export type AudioDeviceSettings = {
  echoCancellation: boolean;
  noiseSuppression: boolean;
  autoGainControl: boolean;
  voiceIsolation: boolean;
};

export default class FinVoiceService extends Service {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked sessions: FinVoiceSession[] = [];
  @tracked websocket: WebSocket | null = null;
  @tracked wavStreamPlayer: WavStreamPlayer | null = null;
  @tracked wavRecorder: WavRecorder | null = null;
  @tracked websocketState: WebsocketState = 'disconnected';
  @tracked isMuted = true;
  @tracked vadSettings: FinVoiceVadSettings = {
    threshold: 0.5,
    prefixPaddingMs: 300,
    silenceDurationMs: 500,
  };
  @tracked selectedVoice: FinVoiceVoice = 'ballad';
  @tracked audioDeviceSettings: AudioDeviceSettings = {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
    // This is not supported on all browsers, but we can always send this since it will be ignored if not supported
    voiceIsolation: false,
  };

  get isConnected() {
    return this.websocketState === 'connected';
  }

  async connectStream(): Promise<void> {
    try {
      this.websocketState = 'connecting';
      this.sessions = [
        ...this.sessions,
        {
          sessionId: `stream_${crypto.randomUUID()}`,
          sessionStartTimestamp: Date.now(),
          sessionEndTimestamp: null,
          sessionError: null,
          events: [],
          conversation: {
            messages: [],
          },
        },
      ];

      this.websocket = await this._createWebSocket();
      this._setupWebsocketHandlers();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.voice-playground.failed-to-connect-message'),
      );
      console.error('Failed to connect to voice server', error);

      this._onConnectionClose();

      this._updateLastSession('sessionError', error.message || error.toString());
    }
  }

  async disconnectStream(): Promise<void> {
    if (!this.websocket) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.voice-playground.no-connection-message'),
      );
      return;
    }

    this.websocketState = 'disconnecting';

    await this._endWavRecorder();
    this.wavStreamPlayer?.interrupt();
    this.websocket.close(1000);
  }

  async _createWebSocket(): Promise<WebSocket> {
    let { ws_url } = await get('/ember/fin_voice_playground/start_session', {
      app_id: this.appService.app.id,
    });

    return new WebSocket(ws_url);
  }

  _setupWebsocketHandlers() {
    if (!this.websocket) {
      throw new Error('WebSocket not initialized');
    }

    this.websocket.onopen = async () => {
      await this._onConnectionOpen();
    };

    this.websocket.onclose = (event) => {
      if (!event.wasClean) {
        console.error('WebSocket connection closed unexpectedly', event.code, event.reason);
      }

      this._onConnectionClose();
    };

    this.websocket.onmessage = (event) => {
      this._onMessage(event);
    };

    this.websocket.onerror = (error) => {
      console.error('An error occurred on the websocket', error);
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.voice-playground.websocket-error-message'),
      );
      this._updateLastSession('sessionError', error.toString());
    };
  }

  async _onConnectionOpen() {
    try {
      if (!this.websocket) {
        return;
      }

      let session = this.sessions[this.sessions.length - 1];
      let sessionId = session.sessionId;

      let startMessage: FinVoiceStartEvent = {
        direction: 'client',
        event: 'start',
        event_id: this._generateEventId(),
        timestamp: Date.now(),
        start: {
          streamSid: sessionId,
          vadSettings: this.vadSettings,
          voice: this.selectedVoice,
        },
      };

      this.websocket.send(JSON.stringify(startMessage));
      this._addConversationItemToSession({
        itemId: startMessage.event_id,
        previousItemId: null,
        status: 'completed',
        timestamp: startMessage.timestamp,
        type: 'start',
        streamId: sessionId,
        vadSettings: this.vadSettings,
        voice: this.selectedVoice,
      });
      this._addEventToSession(startMessage);

      this._connectWavStreamPlayer();
      await this._connectWavRecorder();

      this.websocketState = 'connected';
    } catch (error) {
      console.error('Failed to start session', error);

      this.notificationsService.notifyError(
        this.intl.t('ai-agent.voice-playground.error-on-starting-session'),
      );

      this._updateLastSession('sessionError', error.message || error.toString());

      this.websocket?.close();
    }
  }

  async _onConnectionClose() {
    await this._endWavRecorder();
    this.wavStreamPlayer?.interrupt();

    this.wavRecorder = null;
    this.wavStreamPlayer = null;
    this.websocket = null;

    this.websocketState = 'disconnected';

    this._updateLastSession('sessionEndTimestamp', Date.now());
  }

  async _onMessage(message: MessageEvent) {
    let data: FinVoiceEvent = JSON.parse(message.data) as FinVoiceEvent;

    data.direction = 'server';

    // If we don't have a timestamp, set it to when we received the message
    if (!data.timestamp) {
      data.timestamp = Date.now();
    }

    switch (data.event) {
      case 'media':
        this._onMedia(data);
        break;
      case 'clear':
        this._onClear();
        this._addEventToSession(data);
        break;
      case 'create_conversation_item':
        this._onCreateConversationItem(data);
        this._addEventToSession(data);
        break;

      case 'user_transcript_item':
        this._onUserTranscriptItem(data);
        this._addEventToSession(data);
        break;

      case 'user_transcript_error':
        this._onUserTranscriptError(data);
        this._addEventToSession(data);
        break;

      case 'fin_transcript_item_delta':
        this._onFinTranscriptDelta(data);
        break;

      case 'fin_transcript_item_done':
        this._onFinTranscriptDone(data);
        this._addEventToSession(data);
        break;

      case 'tool_call_done':
        this._onToolCallDone(data);
        this._addEventToSession(data);
        break;

      default:
        data.event = data.event || 'unknown_event';
        this._addEventToSession(data);
        break;
    }
  }

  _onMedia(data: FinVoiceEventMedia) {
    if (!data.media?.payload) {
      return;
    }

    let audioData = new Uint8Array(
      atob(data.media.payload)
        .split('')
        .map((c) => c.charCodeAt(0)),
    );
    this.wavStreamPlayer?.add16BitPCM(
      audioData.buffer,
      data.mark,
      data.mark ? this._handleChunkPlayed.bind(this) : undefined,
    );
  }

  _onClear() {
    this.wavStreamPlayer?.interrupt();
  }

  _onUserTranscriptItem(data: FinVoiceUserTranscript) {
    let session = this.sessions[this.sessions.length - 1];

    let message = session.conversation.messages.find((message) => message.itemId === data.item_id);

    if (message && message.type === 'message') {
      message.transcript = data.transcript;
      message.status = 'completed';
    }

    this.sessions = [...this.sessions];
  }

  _onUserTranscriptError(data: FinVoiceUserTranscriptError) {
    let session = this.sessions[this.sessions.length - 1];

    let message = session.conversation.messages.find((message) => message.itemId === data.item_id);

    if (!session || !message || message.type !== 'message') {
      return;
    }

    message.status = 'error';
    message.errorCode = data.error.code;

    if (data.error.code === 'audio_unintelligible') {
      message.transcript = this.intl.t(
        'ai-agent.voice-playground.transcription-error-code-audio-unintelligible',
      );
    } else {
      message.transcript = this.intl.t('ai-agent.voice-playground.transcription-error');
    }

    this.sessions = [...this.sessions];
  }

  _onFinTranscriptDelta(data: FinVoiceFinTranscriptDelta) {
    let session = this.sessions[this.sessions.length - 1];
    let message = session.conversation.messages.find((message) => message.itemId === data.item_id);

    if (message && message.type === 'message') {
      // If the message was loading before we should clear the transcript
      if (message.status === 'loading') {
        message.status = 'in_progress';
        message.transcript = '';
      }

      message.transcript += data.delta;
    }

    this.sessions = [...this.sessions];
  }

  _onFinTranscriptDone(data: FinVoiceFinTranscriptDone) {
    let session = this.sessions[this.sessions.length - 1];
    let message = session.conversation.messages.find((message) => message.itemId === data.item_id);

    if (message && message.type === 'message') {
      message.transcript = data.transcript;
      message.status = 'completed';
    }

    this.sessions = [...this.sessions];
  }

  _onToolCallDone(data: FinVoiceToolCallDone) {
    let session = this.sessions[this.sessions.length - 1];
    let message = session.conversation.messages.find((message) => message.itemId === data.item_id);

    if (message && message.type === 'function_call') {
      message.status = 'completed';
      message.toolArguments = data.arguments;
    }

    this.sessions = [...this.sessions];
  }

  _onCreateConversationItem(data: FinVoiceMessage) {
    // TODO: It would be better to move this logic to the server at some point.
    // We want to skip the first item because it is an instruction
    if (data.previous_item_id === null) {
      return;
    }

    let type = data.item.type;

    switch (data.item.type) {
      case 'message': {
        let message: FinVoiceConversationEntryMessage = {
          itemId: data.item.id,
          previousItemId: data.previous_item_id,
          role: data.item.role,
          status: 'loading',
          transcript: this.intl.t('ai-agent.voice-playground.transcript-in-progress'),
          timestamp: data.timestamp,
          type: 'message',
        };

        this._addConversationItemToSession(message);

        break;
      }
      case 'function_call': {
        let message: FinVoiceConversationEntryFunctionCall = {
          itemId: data.item.id,
          previousItemId: data.previous_item_id,
          status: 'loading',
          timestamp: data.timestamp,
          type: 'function_call',
          toolName: data.item.name,
          toolCallId: data.item.call_id,
          toolArguments: data.item.arguments,
        };

        this._addConversationItemToSession(message);

        break;
      }
      case 'function_call_output': {
        let session = this.sessions[this.sessions.length - 1];

        let callId = data.item.call_id;

        let message = session.conversation.messages.find(
          (message) => message.type === 'function_call' && message.toolCallId === callId,
        );

        if (message && message.type === 'function_call') {
          message.toolOutput = data.item.output;
        }

        break;
      }
      default:
        console.error('Unknown item type', type);
        break;
    }

    this.sessions = [...this.sessions];
  }

  _connectWavStreamPlayer() {
    this.wavStreamPlayer = new WavStreamPlayer({ sampleRate: SAMPLE_RATE });
    this.wavStreamPlayer.connect();
  }

  async _connectWavRecorder() {
    this.wavRecorder = new WavRecorder({
      sampleRate: SAMPLE_RATE,
      audioDeviceSettings: this.audioDeviceSettings,
    });
    await this.wavRecorder.begin();
    await this.wavRecorder.record(this._handleAudioData.bind(this), BUFFER_SIZE);
    this.isMuted = false;
  }

  async muteAudio() {
    this.isMuted = true;
  }

  async unmuteAudio() {
    this.isMuted = false;
  }

  _handleAudioData(data: { mono: ArrayBuffer }) {
    if (data.mono.byteLength === 0) {
      return;
    }

    if (!this.websocket) {
      console.error('WebSocket not initialized');
      return;
    }

    let mono = data.mono;

    // TODO after event loop changes see if we can use built in pause.
    if (this.isMuted) {
      // Create a view of the audio data as Int16Array and set all samples to 0
      let view = new Int16Array(mono);
      view.fill(0);
      mono = view.buffer;
    }

    let audioBuffer = arrayBufferToBase64(mono);

    let mediaMessage: FinVoiceMediaMessage = {
      direction: 'client',
      event_id: this._generateEventId(),
      event: 'media',
      media: {
        payload: audioBuffer,
        timestamp: Date.now(),
      },
      timestamp: Date.now(),
    };

    this.websocket.send(JSON.stringify(mediaMessage));
  }

  _handleChunkPlayed(trackId: string) {
    if (!this.websocket) {
      console.error('WebSocket not initialized');
      return;
    }

    if (!trackId) {
      console.error('Track ID is required');
      return;
    }

    let markMessage: FinVoiceMarkMessage = {
      direction: 'client',
      event: 'mark',
      event_id: this._generateEventId(),
      timestamp: Date.now(),
      mark: {
        name: trackId,
      },
    };

    this.websocket.send(JSON.stringify(markMessage));
  }

  _generateEventId() {
    return `evt_${crypto.randomUUID()}`;
  }

  async _endWavRecorder() {
    if (this.wavRecorder && this.wavRecorder.recording) {
      await this.wavRecorder.end();
    }

    this.isMuted = true;
  }

  _addEventToSession(event: FinVoiceEvent) {
    let session = this.sessions[this.sessions.length - 1];
    session.events = [...session.events, event];
    this.sessions = [...this.sessions];
  }

  _addConversationItemToSession(message: FinVoiceConversationEntry) {
    let session = this.sessions[this.sessions.length - 1];

    if (session) {
      session.conversation.messages = [...session.conversation.messages, message];
    }

    this.sessions = [...this.sessions];
  }

  _updateLastSession<K extends keyof Pick<FinVoiceSession, 'sessionEndTimestamp' | 'sessionError'>>(
    key: K,
    value: Pick<FinVoiceSession, K>[K],
  ) {
    let session = this.sessions[this.sessions.length - 1];

    if (session) {
      session[key] = value;
      this.sessions = [...this.sessions];
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    finVoiceService: FinVoiceService;
    'fin-voice-service': FinVoiceService;
  }
}
