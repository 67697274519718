/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Service from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { type TopicItem } from 'embercom/components/reporting/automation/ai-insights/topics-side-panel';
import type AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { type EntityType } from 'embercom/models/data/entity-types';

export default class ContentSuggestionsService extends Service {
  @tracked declare listApi: AiContentLibraryApi;
  @tracked selectedTopicItems?: Array<ContentWrapper>;
  @tracked topics: Array<TopicItem> = [];

  removeSuggestionFromList(content: { entity_id: number; entity_type: EntityType }) {
    this.removeFromContentWrapperList(content);
    this.removeFromSelectedTopicItems(content);
    this.updateTopicList();
  }

  updateTopicList() {
    this.topics = [];
    this.listApi?.contentWrappers.forEach((wrapper) => {
      let topic = wrapper.contentReviewSuggestionMetadata.topic;
      let existing = this.topics.find((t) => t.topic === topic);

      if (existing) {
        existing.count++;
        existing.items = [...existing.items, wrapper];
        wrapper.contentReviewSuggestionMetadata.included_conversations?.forEach(
          (conversationId: number) => {
            existing?.conversationIds.add(conversationId);
          },
        );
      } else {
        let conversationIds = new Set<number>();
        wrapper.contentReviewSuggestionMetadata.included_conversations?.forEach(
          (conversationId: number) => {
            conversationIds.add(conversationId);
          },
        );
        this.topics.push({ topic, count: 1, items: [wrapper], conversationIds });
      }
    });
    this.topics = this.topics
      .filter((topic) => !isEmpty(topic.topic))
      .sort((a, b) => b.conversationIds.size - a.conversationIds.size);
  }

  private removeFromContentWrapperList(content: { entity_id: number; entity_type: EntityType }) {
    if (this.listApi) {
      let index = this.listApi.contentWrappers.findIndex(
        (wrapper) =>
          wrapper.entityId === content.entity_id && wrapper.entityType === content.entity_type,
      );
      if (index !== -1) {
        this.listApi.contentWrappers = [
          ...this.listApi.contentWrappers.slice(0, index),
          ...this.listApi.contentWrappers.slice(index + 1),
        ];
      }
    }
  }

  private removeFromSelectedTopicItems(content: { entity_id: number; entity_type: EntityType }) {
    if (this.selectedTopicItems) {
      let index = this.selectedTopicItems.findIndex(
        (wrapper) =>
          wrapper.entityId === content.entity_id && wrapper.entityType === content.entity_type,
      );
      if (index !== -1) {
        this.selectedTopicItems = [
          ...this.selectedTopicItems.slice(0, index),
          ...this.selectedTopicItems.slice(index + 1),
        ];
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    contentSuggestionsService: ContentSuggestionsService;
    'content-suggestions-service': ContentSuggestionsService;
  }
}
