/* RESPONSIBLE TEAM: team-reporting */

import Service, { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type ReportingViews from './reporting-views';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { reportsWithReplacementIds } from './reporting-views';
interface Favorites {
  id: string;
  name: string;
  route: string;
  isStaticReport: boolean;
}

export default class FavoritesService extends Service {
  @service declare store: Store;
  @service declare reportingViews: ReportingViews;
  @service intl!: IntlService;
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare intercomEventService: any;

  @tracked favoriteReportIds: string[] = [];

  isSetup = false;

  @task({ drop: true })
  *preloadFavorites() {
    if (!this.isSetup) {
      let promises: Array<Promise<void>> = [taskFor(this.fetchSettings).perform()];
      yield Promise.all(promises);
      this.isSetup = true;
    }
  }

  @task({ drop: true })
  private *fetchSettings(): TaskGenerator<void> {
    let adminId = this.appService.app.currentAdmin.id;
    let appSettings =
      this.store.peekRecord('admin-teammate-app-settings', adminId) ||
      (yield this.store.findRecord('admin-teammate-app-settings', adminId));
    this.favoriteReportIds = appSettings.get('favoriteReports');
  }

  get favoriteReports(): Favorites[] {
    return this.favoriteReportIds
      .filter((favoriteId) => isPresent(this.reportingViews.allReportsById[favoriteId]))
      .map((favoriteId) => {
        return this.reportingViews.allReportsById[favoriteId];
      })
      .reverse();
  }

  trackAnalyticsEvent(data: Record<string, string>) {
    let coreData = {
      section: 'reports',
      context: 'favorites',
      place: 'create_report_btn',
    };

    this.intercomEventService.trackAnalyticsEvent({
      ...data,
      ...coreData,
    });
  }

  isFavoriteReport(reportId: string): boolean {
    return Boolean(this.favoriteReportIds.find((id) => id === reportId));
  }

  private getObject(favoriteId: any) {
    return isNaN(favoriteId) ? 'static_report' : 'custom_report';
  }

  @task({ drop: true })
  *addToFavorites(favoriteId: string): TaskGenerator<void> {
    try {
      let response = yield post('/ember/admins/teammate_app_settings/add_report_to_favorites', {
        favorite_report_ids: [favoriteId],
        app_id: this.appService.app.id,
      });
      this.favoriteReportIds = response.favorite_reports;
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.report.added-to-favorites-notification'),
      );
      this.trackAnalyticsEvent({
        action: 'added',
        object: this.getObject(favoriteId),
      });
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t('reporting.custom-reports.report.favorites-limit-exceeded'),
      );
    }
  }

  @task({ drop: true })
  *removeFromFavorites(favoriteId: string): TaskGenerator<void> {
    try {
      let response = yield post(
        '/ember/admins/teammate_app_settings/remove_report_from_favorites',
        {
          favorite_report_ids: [favoriteId],
          app_id: this.appService.app.id,
        },
      );
      this.favoriteReportIds = response.favorite_reports;
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.report.removed-from-favorites-notification'),
      );
      this.trackAnalyticsEvent({
        action: 'removed',
        object: this.getObject(favoriteId),
      });
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t('reporting.custom-reports.report.save-error'),
      );
    }
  }

  @task({ drop: true })
  *addOrRemoveFavorite(favoriteId: string): TaskGenerator<void> {
    if (this.isFavoriteReport(favoriteId)) {
      yield taskFor(this.removeFromFavorites).perform(favoriteId);
    } else {
      yield taskFor(this.addToFavorites).perform(favoriteId);
    }
  }

  hasMigrationReplacement(favoriteId: string): boolean {
    return reportsWithReplacementIds.includes(favoriteId);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'favorites-service': FavoritesService;
  }
}

declare module '@ember/service' {
  interface Registry {
    favoritesService: FavoritesService;
    'favorites-service': FavoritesService;
  }
}
