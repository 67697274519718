/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { adminHasSeen, markViewAsSeen } from 'embercom/lib/admin-view-tracking';
import type Store from '@ember-data/store';

export default class BannerDismissalService extends Service {
  @service declare store: Store;

  @action
  async dismiss(key: string) {
    return await markViewAsSeen(this.store, key);
  }

  @action
  async hasDismissed(key: string): Promise<boolean> {
    return await adminHasSeen(this.store, key);
  }
}
