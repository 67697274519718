/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { computed } from '@ember/object';
import {
  alias,
  and,
  bool,
  equal,
  filter,
  filterBy,
  mapBy,
  not,
  notEmpty,
  or,
  readOnly,
  reads,
} from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import {
  ANSWER_BOT_ESSENTIAL_ID,
  CORE_START_ID,
  CORE_GROW_ID,
  CORE_STARTER_BASE_ID,
  defaultSolutionId,
  EARLY_STAGE_BUNDLE,
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  PRICING_5_X_ANNUAL_DISCOUNT_PERCENTAGES,
  PRICING_5_X_CORE_PLANS,
  PRICING_5_X_PRICING_MODELS,
  SALES_MANAGED_COHORT,
  SALES_SOLD_COHORT,
  VBP_1_X_PRICING_MODELS,
  VBP_2_X_EARLY_STAGE_PRICING_MODELS,
  VBP_2_X_INCLUDING_EARLY_STAGE_PRICING_MODELS,
  VBP_2_X_PRICING_MODELS,
  WHATSAPP_BASE_ID,
} from 'embercom/lib/billing';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import ImmutableProduct from 'embercom/lib/immutable-product';
import { includedProductIds } from 'embercom/lib/intershop/intershop';
import PlanUpdater from 'embercom/lib/plan-updater';
import { captureException } from 'embercom/lib/sentry';
import { StripeMigrationType } from 'embercom/models/billing/stripe-migration';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

export default Service.extend({
  appService: service(),
  purchaseAnalyticsService: service(),
  app: readOnly('appService.app'),
  priceFetcherService: service(),
  store: service(),
  customer: null,
  products: null,
  prices: null,
  seatUsageUpdated: false,
  stripeMigration: null,
  plans: computed('products', function () {
    let array = (this.products ?? []).mapBy('plans');
    return array.reduce(function (initial, current) {
      return initial.concat(current.toArray());
    }, []);
  }),
  addons: filterBy('products', 'addon', true),
  activePlans: filterBy('plans', 'active', true),
  activePlanTrials: filterBy('plans', 'activeTrial', true),
  activePlanTrialIds: mapBy('activePlanTrials', 'idAsNumber'),
  inactivePlans: filterBy('plans', 'active', false),
  inactivePlanTrials: filter('inactivePlans', function (plan) {
    return plan.get('latestTrialEndedAt') !== null;
  }),
  inactivePlanTrialsSortedByLatest: computed('inactivePlanTrials', function () {
    let sortedInactivePlanTrials = this.inactivePlanTrials.sort((a, b) => {
      return b.get('latestTrialEndedAt') - a.get('latestTrialEndedAt');
    });
    return sortedInactivePlanTrials;
  }),
  inactivePlanTrialIdsSortedByLatest: mapBy('inactivePlanTrialsSortedByLatest', 'idAsNumber'),
  // billableCustomerPlan is set in billing based on the tier of plan in the customers subscripton
  // This does not include products that are on trial
  billableCustomerPlans: filterBy('plans', 'billableCustomerPlan', true),
  originalActivePlans: computed('plans.[]', 'activePlans.[]', function () {
    return this.plans.filter((plan) => plan.get('content.active'));
  }),
  activePlanIds: mapBy('activePlans', 'idAsNumber'),
  billableCustomerPlanIds: mapBy('billableCustomerPlans', 'idAsNumber'),
  totalAmountPostCoupon: null,
  isMessengerInstalled: readOnly('app.hasAnyInstalledAtDate'),
  earlyStagePartner: readOnly('customer.earlyStagePartner'),
  noValidEarlyStageApplication: not('app.validEarlyStageApplicant'),
  eligibletoEditAsRegularCustomer: or(
    'noValidEarlyStageApplication',
    'customer.hasActiveSubscription',
  ),
  featureName: null,
  planIds: [],
  solutionId: null,
  migrationPrices: alias('latestMigrationPrices'),
  migrationState: null,
  pricePerInboxSeat: null,
  earlyStageGraduation: null,
  isTriallingAllOfIntercom: computed('billableCustomerPlanIds', 'activePlanTrialIds', function () {
    return this.billableCustomerPlanIds.length === 0 && this.activePlanTrialIds.length > 0;
  }),

  canEditCurrentSubscription: computed(
    'app.{canUseStandalone,isSalesforceContracted}',
    'customer.{canModifyOrRemoveProducts,currentEarlyStageCustomer}',
    'hasActiveSalesOnlyPlans',
    'isTriallingAllOfIntercom',
    function () {
      return (
        !this.app.isSalesforceContracted &&
        !this.app.canUseStandalone &&
        this.customer.canModifyOrRemoveProducts &&
        !this.customer.currentEarlyStageCustomer &&
        !this.hasActiveSalesOnlyPlans &&
        !this.isTriallingAllOfIntercom
      );
    },
  ),

  isSelfServeAnnualCustomer: computed(
    'app.isSalesforceContracted',
    'customer.{hasCustomPricing,subscription.isBilledAnnually}',
    function () {
      return !!(
        !this.app.isSalesforceContracted &&
        !this.customer.hasCustomPricing &&
        this.customer.subscription?.isBilledAnnually
      );
    },
  ),

  isSelfServeMonthlyCustomer: computed(
    'app.{isSalesforceContracted,canUseStandalone}',
    'customer.{hasActiveSubscription,subscription.isBilledMonthly}',
    function () {
      return (
        !this.app.isSalesforceContracted &&
        !this.app.canUseStandalone &&
        this.customer.subscription?.isBilledMonthly &&
        this.customer.hasActiveSubscription &&
        !this.customer.hasActiveTrialSubscription
      );
    },
  ),

  isP51SelfServeMonthlyCustomer: computed(
    'app.onPricing5_1',
    'isSelfServeMonthlyCustomer',
    'customer.currentlyOnEarlyStage',
    function () {
      return (
        this.app.onPricing5_1 &&
        this.isSelfServeMonthlyCustomer &&
        !this.customer.currentlyOnEarlyStage
      );
    },
  ),

  isP5SelfServeAnnualCustomer: and('app.onPricing5', 'isSelfServeAnnualCustomer'),

  isSalesSoldCustomer: equal('customer.customerCohort', SALES_SOLD_COHORT),

  isSalesManagedCustomer: equal('customer.customerCohort', SALES_MANAGED_COHORT),

  selfServeRenewalDate: computed(
    'isSelfServeAnnualCustomer',
    'customer.subscription.{isBilledAnnually,termStartDate}',
    function () {
      let termStartDate = this.customer.subscription?.termStartDate;
      if (!termStartDate) {
        throw new Error('Cannot determine renewal date without termStartDate');
      }
      if (!this.isSelfServeAnnualCustomer) {
        throw new Error('Cannot determine renewal date unless self serve annual customer');
      }

      let yearsSinceStart = moment().diff(termStartDate, 'years');

      return moment(termStartDate).add(12, 'months').add(yearsSinceStart, 'years');
    },
  ),

  activeSalesOnlyPlans: filterBy('activePlans', 'selfServe', false),
  hasActiveSalesOnlyPlans: notEmpty('activeSalesOnlyPlans'),

  pricingMigrationDate: alias('migrationState.date_migration_complete'),
  migrationDate: readOnly('pricingMigrationDate'),
  formattedMigrationDate: computed('migrationDate', function () {
    return moment(this.migrationDate).format(dateAndTimeFormats.date);
  }),
  timeToMigrationDateText: computed('migrationDate', function () {
    return moment().to(moment(this.migrationDate));
  }),

  appIsInPricingTransition: computed(
    'migrationState.{auto_seat_assignment_done,date_migration_complete}',
    function () {
      let migrationStarted = this.get('migrationState.auto_seat_assignment_done');
      let migrationFinished = moment().isAfter(
        moment(this.get('migrationState.date_migration_complete')),
      );
      return migrationStarted && !migrationFinished;
    },
  ),

  isDirty: computed('products.@each.updating', function () {
    let products = this.products;

    return products.any((product) => {
      return product.get('updating') === true;
    });
  }),

  // Final price MODEL billed to customer
  currentPrice: computed('prices.[]', 'activePlanIds.[]', function () {
    if (this.get('prices.length')) {
      return this.prices.find((price) => price.hasSamePlans(this.activePlanIds));
    }
  }),

  currentPricingModelIdentifier: computed('currentPrice', function () {
    return this.currentPrice ? this.currentPrice.pricingModelIdentifier : null;
  }),

  noActiveSubscriptionPricingModelIdentifier: reads('prices.firstObject.pricingModelIdentifier'),

  onVbp1_X: computed('currentPricingModelIdentifier', function () {
    return VBP_1_X_PRICING_MODELS.includes(this.currentPricingModelIdentifier);
  }),

  get onSelfServeVBP1Plan() {
    return (
      this.billableCustomerPlanIds.includes(Number(CORE_START_ID)) ||
      this.billableCustomerPlanIds.includes(Number(CORE_GROW_ID))
    );
  },

  onVbp2_X: computed('currentPricingModelIdentifier', function () {
    return VBP_2_X_PRICING_MODELS.includes(this.currentPricingModelIdentifier);
  }),

  get onSelfServeVBP2Plan() {
    return this.billableCustomerPlanIds.includes(Number(CORE_STARTER_BASE_ID));
  },

  onVbp2_X_EarlyStage: computed('currentPricingModelIdentifier', function () {
    return VBP_2_X_EARLY_STAGE_PRICING_MODELS.includes(this.currentPricingModelIdentifier);
  }),

  onVbp2_X_IncludingEarlyStage: computed('currentPricingModelIdentifier', function () {
    return VBP_2_X_INCLUDING_EARLY_STAGE_PRICING_MODELS.includes(
      this.currentPricingModelIdentifier,
    );
  }),

  onPricing5_X: computed('currentPricingModelIdentifier', function () {
    return PRICING_5_X_PRICING_MODELS.includes(this.currentPricingModelIdentifier);
  }),

  noActiveSubscriptionPricing5_X: computed(
    'noActiveSubscriptionPricingModelIdentifier',
    function () {
      return PRICING_5_X_PRICING_MODELS.includes(this.noActiveSubscriptionPricingModelIdentifier);
    },
  ),

  isPricing5CorePlan(planId) {
    return PRICING_5_X_CORE_PLANS.includes(`${planId}`);
  },

  get activePricing5CorePlanId() {
    return this.billableCustomerPlanIds.find((planId) => this.isPricing5CorePlan(planId));
  },

  annualSavingPercentageForP5Plan(planId) {
    if (!planId) {
      return;
    }
    return PRICING_5_X_ANNUAL_DISCOUNT_PERCENTAGES[planId];
  },

  get annualSavingForCurrentP5CorePlan() {
    return this.annualSavingPercentageForP5Plan(this.activePricing5CorePlanId);
  },

  migrationPrice: computed('activePlans.[]', 'migrationPrices.[]', function () {
    if (!this.migrationPrices) {
      return null;
    }

    let activePlanIds = this.activePlanIds;
    return this.migrationPrices.find((price) => price.hasSamePlans(activePlanIds));
  }),

  // If changing a subscription, price billed to customer before editing
  get originalPrice() {
    let currentActivePlans = this.plans.filter((plan) => plan.get('content.active'));
    if (this.prices?.length) {
      let planIds = currentActivePlans.mapBy('idAsNumber');
      return this.prices.find((price) => price.hasSamePlans(planIds));
    }
  },

  get isEarlyStageProgression() {
    return (
      this.earlyStageGraduation?.pricing5_X_Graduation &&
      this.appService.app.canUseNewProgressionExperience
    );
  },

  get isEligibleForAnnualPlan() {
    let customerSubscriptionStateEligibleForAnnualConversion =
      !this.customer.hasActiveSubscription || this.customer.hasActiveTrialSubscription;

    let customerInEarlyStageFlow =
      this.customer.validEarlyStageApplicant || this.isEarlyStageProgression;

    return !!(
      this.appService.app.show5_1PricingModal &&
      customerSubscriptionStateEligibleForAnnualConversion &&
      !customerInEarlyStageFlow
    );
  },

  get currentPricing5CorePlanSolutionId() {
    return this.activePricing5CorePlanId
      ? defaultSolutionId(String(this.activePricing5CorePlanId))
      : null;
  },

  currentNumberOfInboxSeats: computed('appService.app.humanAdmins.[]', function () {
    return this.get('app.humanAdmins').filter((admin) => admin.get('hasInboxAccess')).length;
  }),

  totalAmount: alias('currentPrice.amountToTwoDecimals'),

  couponDiscountAmountFormatted: alias('currentPrice.couponDiscountAmountFormatted'),

  couponAmount: computed('currentPrice', function () {
    return this.get('currentPrice.preDiscountAmount') - this.get('currentPrice.amount');
  }),
  answerBotPlan: findBy('activePlans', 'id', ANSWER_BOT_ESSENTIAL_ID),
  answerBotPlanStartDate: alias('answerBotPlan.activatedAt'),

  totalAmountAfterMigration: alias('migrationPrice.amountToTwoDecimals'),

  couponDiscountAmountAfterMigrationFormatted: alias(
    'migrationPrice.couponDiscountAmountFormatted',
  ),

  getPriceFromPlanIds(planIds, pricingModelIdentifier = null) {
    return this.prices?.find((price) => {
      if (pricingModelIdentifier && price.pricingModelIdentifier !== pricingModelIdentifier) {
        return false;
      }
      return price.hasSamePlans(planIds);
    });
  },

  isPriceLoaded(planIds, pricingModelIdentifier) {
    return !!this.getPriceFromPlanIds(planIds, pricingModelIdentifier);
  },

  refreshCustomer() {
    return this.store
      .findRecord('billing/customer', this.get('app.customer_id'), { reload: true })
      .then((customer) => {
        this.set('app.customer', customer);
        return customer;
      });
  },

  fetchCustomer(customerId) {
    return this.store.findRecord('billing/customer', customerId, { reload: true });
  },

  reportProductChangesForAnalytics(locationInfo) {
    this.products.forEach((product) => {
      if (product.updating) {
        this.purchaseAnalyticsService.trackEvent({
          action: `product ${product.newStatus}`,
          product_name: product.name,
          tier: product.activePlan?.name,
          old_tier: product.previousActivePlan?.name,
          is_trial: product.trialable,
          object: `${product.name}`,
          ...locationInfo,
        });
      }
    });
  },

  /**
   * Bulk request prices to add to customer service
   *
   * @method bulkLoadPrices
   * @param {Object[]} params An array of request parameter objects.
   *
   *
   * @example
   * ```
   * requests = [{planIds:[1,4,15]}, {planIds: [3,5], includeTiers: 250}]
   * bulkLoadPrices(requests);
   * ```
   */
  async bulkLoadPrices(params) {
    this.set('prices', await this.priceFetcherService.bulkLoadPrices(params, this.prices));
  },

  async fetchCurrentPrice(params = {}) {
    this.set(
      'prices',
      await this.priceFetcherService.fetchCurrentPrice(params, this.activePlanIds, this.prices),
    );
  },

  async fetchCurrentPricePerUsage(params = {}) {
    return await this.priceFetcherService.fetchCurrentPricePerUsage(params, this.activePlanIds);
  },

  async fetchSolutionPrices(
    solutionId,
    couponCode,
    source,
    includePricingByBillingPeriod,
    seats = 0,
    includeTiers,
    usageParameterOverrides = undefined,
  ) {
    return await this.priceFetcherService.fetchSolutionPrices(
      solutionId,
      couponCode,
      source,
      includePricingByBillingPeriod,
      seats,
      includeTiers,
      usageParameterOverrides,
    );
  },

  async fetchSolutionPricesWithSeats(
    solutionId,
    planIds,
    couponCode,
    source,
    includePricingByBillingPeriod,
    agentCount,
    usageParameterOverrides = undefined,
  ) {
    return await this.priceFetcherService.fetchSolutionPricesWithSeats(
      solutionId,
      planIds,
      couponCode,
      source,
      includePricingByBillingPeriod,
      agentCount,
      usageParameterOverrides,
    );
  },

  ensureDataIsLoaded: task(function* (options = {}) {
    if (!this.customer || (options.fetchPrices && !this.prices)) {
      yield this.loadData(options);
    }
  }),

  loadData(
    {
      customer,
      fetchPrices,
      includeTiers,
      overrideCanSeePrice,
      source,
      includePlanCombinationValidation,
      useBillingCycleMetricVariations,
      useShiftedCycleMetricVariations,
      reusePricesIfPossible,
      fetchCurrentPricesOnly,
    } = {
      fetchPrices: true,
      includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
      overrideCanSeePrice: false,
      useBillingCycleMetricVariations: false,
      useShiftedCycleMetricVariations: false,
      fetchCurrentPricesOnly: false,
    },
  ) {
    if (includeTiers && !fetchPrices) {
      throw new Error(
        'If you want to include tiers you must fetch prices as well. Add fetchPrices: true property to your paramters to fix this.',
      );
    }
    let products = this.products
      ? this.products
      : this.app.get('products').map((product) => ImmutableProduct.create({ content: product }));
    this.set('products', products);

    let prices = this.prices;
    let skipFetchingPrices = reusePricesIfPossible && this._isEveryPriceCombinationLoaded;

    if (fetchPrices && !skipFetchingPrices) {
      let combinations = fetchCurrentPricesOnly
        ? [this.activePlanIds]
        : this.defaultPriceCombinations(this.plans, this.activePlanIds);

      prices = this.priceFetcherService.fetchPricesCombinations(
        combinations,
        includeTiers,
        overrideCanSeePrice,
        useBillingCycleMetricVariations,
        useShiftedCycleMetricVariations,
        source,
        includePlanCombinationValidation,
      );
    }

    return hash({
      customer: customer || this.store.findRecord('billing/customer', this.app.get('customerId')),
      prices,
    })
      .then(({ customer, products, prices }) => {
        this.setProperties({
          customer,
          prices,
        });
        return this.loadEarlyStageState({ includeTiers, source });
      })
      .then(() => {
        return {
          customer: this.customer,
          products: this.products,
          prices: this.prices,
        };
      });
  },

  get _isEveryPriceCombinationLoaded() {
    let combinations = this.defaultPriceCombinations(this.plans, this.activePlanIds);

    return combinations
      .filter((combination) => combination?.length > 0)
      .every((combination) => this.isPriceLoaded(combination));
  },

  updateData({ featureName, planIds, overrideExistingPlans }) {
    if (featureName) {
      PlanUpdater.setActivePlansFromFeatureName(featureName, this.products);
    } else if (planIds && planIds.length) {
      PlanUpdater.setActivePlans(planIds, this.products, overrideExistingPlans);
    }
  },

  defaultPriceCombinations(plans, activePlanIds) {
    let allAvailablePlanIds = plans.map((plan) => [plan.idAsNumber]);
    if (activePlanIds.length > 1) {
      let allActiveAddons = this.activePlans
        .map((plan) => {
          if (plan.product.addon) {
            return plan.idAsNumber;
          }
        })
        .compact();
      // Create arrays of plan ids that include all active addons
      // This means prices will exist for combinations of upgrade and downgrade plan combinations
      let allAvailablePlans = plans
        .map((plan) => {
          if (!activePlanIds.includes(plan.idAsNumber) && !plan.product.addon) {
            return [plan.idAsNumber, ...allActiveAddons];
          }
        })
        .compact();
      return [activePlanIds].concat(allAvailablePlanIds).concat(allAvailablePlans);
    }
    return allAvailablePlanIds;
  },

  async loadEarlyStageState(
    { includeTiers, source } = { includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT },
  ) {
    if (!this.customer.currentEarlyStageCustomer) {
      return;
    }
    let earlyStageGraduation = await this.store
      .findRecord('billing/early-stage-graduation', this.get('app.id'))
      .catch((e) => {
        captureException(e);
      });
    this.setProperties({
      earlyStageGraduation,
    });
  },

  earlyStageFuturePlans: computed('earlyStageGraduation.planIds.[]', 'plans', function () {
    let futurePlans = this.get('earlyStageGraduation.planIds');
    return this.plans.filter((plan) => futurePlans.includes(plan.get('idAsNumber')));
  }),

  earlyStageGraduationDate: alias('earlyStageGraduation.graduationDate'),
  earlyStageGraduationStarted: bool('earlyStageGraduation.customerGraduationProcessTriggered'),
  earlyStageGraduationPricingModelIdentifier: alias('earlyStageGraduation.priceSetIdentifier'),

  // In the future, this will update / save the ESA model in the db, but for now it does nothing
  updateEarlyStageFuturePlans() {
    return Promise.resolve();
  },

  addPlanToEarlyStageGraduation(planId) {
    this.get('earlyStageGraduation.planIds').addObject(planId);
  },

  removePlanFromEarlyStageGraduation(planId) {
    this.get('earlyStageGraduation.planIds').removeObject(planId);
  },

  setPlansAsActiveFromEarlyStageGraduation() {
    let planIds = this.get('earlyStageGraduation.planIds');
    planIds = planIds.map((planId) => planId.toString());
    if (planIds.length === 0) {
      this.deactivateAllPlans();
    }
    this.updateData({
      planIds,
      overrideExistingPlans: true,
    });
  },

  resetAll() {
    this.resetProducts();
    this.resetQueryParams();
    // Solve strange recompute bug with original price
    this.set('prices', this.prices);
  },

  resetQueryParams() {
    this.setProperties({ planIds: null, featureName: null, solutionId: null });
  },

  deactivateAllPlans() {
    this.products &&
      this.products.forEach((product) => {
        product.plans &&
          product.plans.forEach((plan) => {
            plan.set('active', false);
          });
      });
  },

  resetProducts() {
    this.products &&
      this.products.forEach((product) => {
        product.plans &&
          product.plans.forEach((plan) => {
            plan.resetState();
          });
      });
  },

  syncPlanIds() {
    this.set('planIds', this.activePlans.mapBy('id'));
  },

  markProductsAsActiveIfEarlyStageApplicant() {
    if (this.eligibletoEditAsRegularCustomer) {
      return;
    }

    PlanUpdater.setActivePlans(EARLY_STAGE_BUNDLE, this.products);
  },
  getPlanMetrics(planId) {
    return this.plans
      .find((plan) => plan.idAsNumber === planId)
      .get('pricingStrategies')
      .map((pricingStrategy) => pricingStrategy.pricingMetric);
  },

  getPlansWithOverlappingPricingMetrics(plan) {
    let planIdAsNumber = plan.idAsNumber;
    let planProductId = plan.product.idAsNumber;

    let planMetrics = this.getPlanMetrics(planIdAsNumber);
    let remainingBillableCustomerPlans = this.plans.filter(
      (plan) => plan.idAsNumber !== planIdAsNumber && plan.get('billableCustomerPlan'),
    );

    let overlappingPlans = remainingBillableCustomerPlans
      .filter((plan) => {
        //Ignore any core plans that exist on the same product
        // Exclude any plans that we do not currently support in intershop
        if (
          plan.product.get('idAsNumber') === planProductId ||
          !includedProductIds().includes(plan.product.id)
        ) {
          return false;
        }
        let otherPlanMetrics = this.getPlanMetrics(plan.idAsNumber);

        return otherPlanMetrics.some((metric) => {
          if (metric === Metric.support_seat_count_or_latest_daily_admin_count) {
            //For VBP2 whatsapp pricing strategy metric is returned as
            //support_seat_count_or_latest_daily_admin_count to work for starter plan
            //need to check if the plan metrics include either of those pricing metrics
            return planMetrics.some(
              (planMetric) =>
                planMetric === Metric.support_seat_count ||
                planMetric === Metric.latest_daily_admin_count,
            );
          }
          if (planIdAsNumber === Number(WHATSAPP_BASE_ID)) {
            let usesCombinedMetric = planMetrics.some((planMetric) =>
              planMetric.includes(Metric.support_seat_count_or_latest_daily_admin_count),
            );
            if (usesCombinedMetric) {
              return (
                metric === Metric.support_seat_count || metric === Metric.latest_daily_admin_count
              );
            }
          }
          return planMetrics.includes(metric);
        });
      })
      .map((plan) => plan.idAsNumber);

    return overlappingPlans;
  },

  async getStripeMigration() {
    let stripeMigration;

    try {
      return await this.store.findRecord('billing/stripe-migration', this.appService.app.id, {
        appId: this.appService.app.id,
        backgroundReload: false,
      });
    } catch (error) {
      return this.peekStripeMigration || this._createStripeMigrationRecord();
    } finally {
      this.setProperties({
        stripeMigration,
      });
    }
  },

  _createStripeMigrationRecord() {
    return this.store.createRecord('billing/stripe-migration', {
      id: this.appService.app.id,
      migrationType: StripeMigrationType.reactive,
      isLocked: false,
      isConfirmedByCustomer: false,
    });
  },

  get canSeePricingMigration() {
    if (
      this.peekStripeMigration &&
      this.peekStripeMigration.isProactive &&
      this.peekStripeMigration.isPending
    ) {
      return true;
    }

    return this.app.canSeeNewPricingMigration ?? false;
  },

  get peekStripeMigration() {
    return this.store.peekRecord('billing/stripe-migration', this.appService.app.id);
  },
});
